import Color from "color";

const isDark = (color: Color) => {
  // @ts-ignore
  let rgb = color.rgb().color;
  let yiq = (rgb[0] * 299 + rgb[1] * 587 + rgb[2] * 114) / 1000;
  return yiq < 150;
};

const fgClass = (colorHex: string) =>
  isDark(Color(colorHex)) ? "light-txt" : "dark-txt";

const fgColor = (colorHex: string) =>
  isDark(Color(colorHex)) ? "#ffffff" : "#000000";

const lbFgColor = (colorHex: string) =>
  isDark(Color(colorHex)) ? colorHex : "#000000";

const lightenColor = (color: string) => {
  const darkestRegex = new RegExp(/#[0-1]/);
  const darkRegex = new RegExp(/#[2-7]/);
  let calculatedLightColor = "";
  if (color === "#000000") return "#E5E5E5";
  else if (darkestRegex.test(color)) {
    calculatedLightColor = Color(color).lighten(8.5).hex();
  } else if (darkRegex.test(color)) {
    calculatedLightColor = Color(color).lighten(1.9).hex();
  } else {
    let lightIndex = 0.1;
    while (lightIndex <= 1) {
      const currentColor = Color(color).lighten(lightIndex).hex();
      calculatedLightColor = currentColor;
      lightIndex += 0.1;
    }
  }
  if (calculatedLightColor === "#FFFFFF") return "#E5E5E5";
  else if (Color(calculatedLightColor).isLight()) {
    return calculatedLightColor;
  } else {
    calculatedLightColor = Color(calculatedLightColor).lighten(0.5).hex();
  }
  return calculatedLightColor;
};

const ContrastForegroundUtility = { fgColor, fgClass, lightenColor, lbFgColor };
export default ContrastForegroundUtility;
