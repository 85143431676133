import { moment } from "./fakeMoment";

const millisecondsTohms = (time: number | string) => {
  if (typeof time == "string") {
    time = parseInt(time);
  }
  if (moment(time).isValid() && time != undefined) {
    let seconds: number | string = time / 1000;
    let hours: number | string = Math.trunc(seconds / 3600);
    seconds = seconds % 3600;
    let minutes: number | string = Math.trunc(seconds / 60);
    seconds = Math.trunc(seconds % 60);
    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;
    return hours < 1
      ? minutes + ":" + seconds
      : hours + ":" + minutes + ":" + seconds;
  } else {
    return "00:00";
  }
};
export default millisecondsTohms;
