import React from "react";
import PropTypes from "prop-types";
import { Event } from "../types";
import Favorite, { OnClickFavIconProps } from "./Favorite";

const hiddenIcon = require("../../styles/imgHide.svg");

const mapImagePosition = {
  NorthEastGravity: {
    top: 0,
    right: 0
  },
  NorthWestGravity: {
    top: 0,
    left: 0
  },
  SouthEastGravity: {
    bottom: 0,
    right: 0
  },
  SouthWestGravity: {
    bottom: 0,
    left: 0
  },
  SouthGravity: {
    bottom: 0,
    margin: "auto",
    left: 0,
    right: 0
  }
};

interface LightBoxImageProps {
  photo: {
    watermarkedImg: string;
    zoomImg: string;
    hidden: boolean;
    isFavorite: boolean;
    id: number;
  };
  event: Event;
  hidden: boolean;
  onClickFavoriteIcon: ({ id, isFavorite }: OnClickFavIconProps) => void;
  showFavIcon?: boolean;
}

interface LightBoxImageState {
  overlayImageSize: number;
}

class LightBoxImage extends React.Component<
  LightBoxImageProps,
  LightBoxImageState
> {
  constructor(props: LightBoxImageProps) {
    super(props);
    this.state = {
      overlayImageSize: 0
    };
  }

  componentDidMount() {
    this.startMagicZoomIfDOMReady();
  }

  startMagicZoomIfDOMReady() {
    if (!!document.getElementById("lightboxZoomMagnifier")) {
      //@ts-ignore
      MagicZoom.start();
    } else setTimeout(() => this.startMagicZoomIfDOMReady());
  }

  imageOnLoadHandler = (imgWidth: number) => {
    if (this.props.event.overlayImageEnabled)
      this.setState({
        overlayImageSize: (this.props.event.overlayImageSize * imgWidth) / 100
      });
  };

  componentDidUpdate() {
    //@ts-ignore
    MagicZoom.update(
      $(".MagicZoom")[0],
      this.props.photo.zoomImg,
      this.props.photo.watermarkedImg
    );
  }

  render() {
    return (
      <div className="dv-imgdetails">
        {this.props.hidden && (
          <span className="geo-visibility-stamp">
            <img src={hiddenIcon} />
          </span>
        )}
        {this.props.event.overlayImageEnabled ? (
          <img
            className="overlay-photo"
            src={this.props.event.overlayImageURL}
            style={{
              //@ts-ignore
              ...mapImagePosition[this.props.event.overlayImagePosition],
              width: this.state.overlayImageSize + "px"
            }}
          />
        ) : null}
        <span className="align-helper" />
        <a
          data-zoom-image={this.props.photo.zoomImg}
          id="lightboxZoomMagnifier"
          className="MagicZoom zoom-photo"
          data-options="zoomMode: magnifier; zoomDistance: 200; smoothing: true; upscale: true; expand: false; rightClick: false;"
          data-mobile-options="zoomMode: magnifier;"
        >
          <img
            className="lightbox-photo"
            onLoad={(e: any) => this.imageOnLoadHandler(e.target.width)}
            src={this.props.photo.watermarkedImg}
          />
        </a>
        {this.props.showFavIcon && (
          <Favorite
            onClickFavoriteIcon={this.props.onClickFavoriteIcon}
            isFavorite={this.props.photo.isFavorite}
            id={this.props.photo.id}
            source="lightbox"
          />
        )}
      </div>
    );
  }
}

export default LightBoxImage;
