import React, { useState } from "react";
// @ts-ignore
import Webcam from "react-webcam";

const WebcamCapture = ({
  closeWebcam,
  captured
}: {
  closeWebcam: () => void;
  captured: (screenshot: string) => void;
}) => {
  const [webcam, setWebcam] = useState();
  const capture = () => {
    // @ts-ignore
    captured(webcam.getScreenshot());
  };
  const videoConstraints = {
    facingMode: "user"
  };
  return (
    <div>
      <Webcam
        audio={false}
        // height={450}
        ref={setWebcam}
        screenshotFormat="image/jpeg"
        videoConstraints={videoConstraints}
        // width={500}
      />{" "}
      <div className="d-flex webcam-foo">
        <a className="btn btn-link" onClick={closeWebcam}>
          Cancel
        </a>
        <a className="btn secondary-btn" onClick={capture}>
          Capture photo
        </a>
      </div>
    </div>
  );
};

export default WebcamCapture;
