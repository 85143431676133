import React from "react";
import MultiSelectInput from "../../../AlbumTag/components/MultiSelectInput";
import ContrastForegroundUtility from "../../../../utils/ContrastForegroundUtility";

interface TagsSectionProps {
  photoId: Array<number>;
  appliedTags: Array<{ name: string; tagId: number; taggableType: string }>;
  contentTagsAPI: string;
  colorStyling?: string;
}

interface TagsSectionState {
  addTags: boolean;
  displayTags: Array<{ name: string; tagId: number; taggableType: string }>;
}

class TagsSection extends React.Component<TagsSectionProps, TagsSectionState> {
  constructor(props: TagsSectionProps) {
    super(props);

    this.state = {
      addTags: false,
      displayTags: []
    };
  }

  hideInputBox = () => this.setState({ addTags: false });

  handlePostCall = (newTags: any) =>
    this.setState({ displayTags: this.sortTagSequence(newTags) });

  componentDidMount() {
    this.setState({
      displayTags: this.sortTagSequence(this.props.appliedTags)
    });
  }

  componentDidUpdate(prevProps: {
    appliedTags: { name: string; tagId: number; taggableType: string }[];
  }) {
    if (prevProps.appliedTags != this.props.appliedTags) {
      this.setState({
        displayTags: this.sortTagSequence(this.props.appliedTags)
      });
    }
  }

  handleClose = () => this.setState({ addTags: false });

  sortTagSequence = (arrayOfTags: any[]) => {
    arrayOfTags.sort((a, b) => {
      if (a.taggableType === "photo" && b.taggableType === "album") {
        return -1; // a should come before b
      } else if (a.taggableType === "album" && b.taggableType === "photo") {
        return 1; // b should come before a
      } else {
        return 0; // no change in order
      }
    });
    return arrayOfTags;
  };

  handleHideTooltip = (index: number) =>
    //@ts-ignore
    $(`#${index}-album-tooltip`).tooltip();

  handleToggle = (index: number) =>
    $(`#${index}-album-tooltip`)
      .attr(
        "data-original-title",
        "Tags inherited from the album. Can be edited in the Upload Photos page."
      )
      //@ts-ignore
      .tooltip("show");

  render() {
    return (
      <div className="photo-tags-div">
        <>
          <div className="tag-heading">
            <div className="title">tags</div>
            <div
              className="add-edit-btn"
              onClick={e => (
                e.preventDefault(), this.setState({ addTags: true })
              )}
              style={{
                color: this.props.colorStyling
                  ? ContrastForegroundUtility.lbFgColor(this.props.colorStyling)
                  : "initial"
              }}
            >
              {!this.state.addTags && "add / edit tags"}
            </div>
          </div>
          {this.state.addTags === false ? (
            <div className="tags-list">
              {this.sortTagSequence(this.state.displayTags).map(
                (tag: any, index: number) => (
                  <span
                    key={`${index}-${tag.name}`}
                    className={
                      tag.taggableType === "album"
                        ? "tag-span album-tag"
                        : "tag-span photo-tag"
                    }
                    id={`${index}-album-tooltip`}
                    data-toggle="tooltip"
                    onMouseEnter={() =>
                      tag.taggableType === "album"
                        ? this.handleToggle(index)
                        : null
                    }
                    onMouseMove={() =>
                      tag.taggableType === "album"
                        ? this.handleHideTooltip(index)
                        : null
                    }
                  >
                    {tag.name}
                  </span>
                )
              )}
            </div>
          ) : null}
          {this.state.addTags && (
            <MultiSelectInput
              appliedTags={this.state.displayTags}
              photoId={this.props.photoId}
              contentTagsAPI={"/api/v1/content_tags"}
              handleClose={this.handleClose}
              tagType={"photo"}
              hideInputBox={this.hideInputBox}
              handlePostCall={this.handlePostCall}
              reloadPage={false}
              blockEdit={"album"}
            />
          )}
        </>
      </div>
    );
  }
}

export default TagsSection;
