import React, { useState, useContext, Fragment, useEffect } from "react";
import ReactS3Uploader from "react-s3-uploader";
import { SelfieSearchContext } from "../SelfieSearchContext";
import ImageUploadAlert from "../../../ImageUploadAlert";
import ajax from "superagent";
import SelfieIcon from "../../../EventGalleryMobile/vectors/SelfieIcon";
import UploadIcon from "../../../EventGalleryMobile/vectors/UploadIcon";
import ContrastForegroundUtility from "../../../../utils/ContrastForegroundUtility";
const delImg = require("../../styles/delete.svg");
const placeholderImage = require("../../images/placeholder-img-ver2.svg");

const UploadSelfie = ({
  closePopup,
  updateImageFilter,
  toggleWebcam,
  setImageURL,
  imageURL,
  previewImage,
  setPreviewImage,
  hideUploadSelfie,
  pageConfiguration
}: {
  updateImageFilter: (imageUrl: string | null, clearSelfie: boolean) => void;
  closePopup: () => void;
  toggleWebcam: () => void;
  setImageURL: (imageURL: string) => void;
  imageURL: string;
  previewImage: string | null;
  setPreviewImage: (imageURL: string) => void;
  hideUploadSelfie: boolean;
  pageConfiguration: {
    primaryColor: string;
    secondaryColor: string;
    accentColor: string;
  };
}) => {
  const [uploadProgress, setUploadProgress] = useState(0);
  const [previewImageState, setPreviewImageState] = useState(previewImage);
  const [imageUploadStart, setImageUploadStart] = useState(false);
  const context = useContext(SelfieSearchContext);

  const imageSelected = (imageFile: File, next: (imageFile: File) => void) => {
    setImageUploadStart(true);
    setPreviewImage(URL.createObjectURL(imageFile));
    next(imageFile);
  };

  useEffect(() => {
    setPreviewImageState(previewImage);
  }, [previewImage]);

  const getSignedUrl = (
    file: File,
    callback: (params: { signedUrl: string }) => any
  ) => {
    ajax
      .get("/s3/signed_url")
      .query({
        bucket: context.selfieSearchS3Bucket,
        contentType: file.type
      })
      .then(data => {
        callback(JSON.parse(data.text));
      })
      .catch(error => {
        console.error(error);
      });
  };

  return (
    <Fragment>
      <div className="filter-selfie-img-div">
        {previewImageState ? (
          <>
            <img
              src={previewImageState}
              className={imageUploadStart ? "uploading" : ""}
            />
            <span className="div-del">
              <span
                className="selfie-del-btn"
                onClick={() => updateImageFilter(null, true)}
              >
                <img src={delImg} />
              </span>
            </span>
          </>
        ) : (
          <img className="bit-m" src={placeholderImage} />
        )}

        {uploadProgress > 0 && uploadProgress < 100 && (
          <div className="uploader">
            <span
              className="uploader-fill"
              style={{ height: `${uploadProgress}%` }}
            ></span>
            <span className="uploader-count">{uploadProgress}%</span>
          </div>
        )}
      </div>

      <div>
        <p className="filter-selfie-p1">
          Please upload or take a photo looking straight ahead to find matching
          photos.
        </p>

        <div className="selfie-btns">
          {!hideUploadSelfie && (
            <label
              className="btn btn-primary d-block selfie-up-btn"
              style={{
                backgroundColor: `${pageConfiguration.primaryColor}`
              }}
            >
              <UploadIcon
                color={ContrastForegroundUtility.fgColor(
                  pageConfiguration.primaryColor
                )}
              />
              <span
                style={{
                  color: `${ContrastForegroundUtility.fgColor(
                    pageConfiguration.primaryColor
                  )}`
                }}
              >
                {context.referenceImage ? "Change Photo" : "Upload Photo"}
              </span>

              <ReactS3Uploader
                className={"dv-upload-input"}
                style={{ display: "none" }}
                getSignedUrl={getSignedUrl}
                accept="image/*"
                preprocess={imageSelected}
                onProgress={completedPercent => {
                  setUploadProgress(completedPercent);
                }}
                onError={console.error}
                onFinish={result => {
                  setUploadProgress(100);
                  setImageURL(result.signedUrl.split("?")[0]);
                  updateImageFilter(result.signedUrl.split("?")[0], false);
                  setImageUploadStart(false);
                }}
                uploadRequestHeaders={{
                  "x-amz-acl": "public-read"
                }}
                scrubFilename={(filename: string) =>
                  filename.replace(/[^\w\d_\-.]+/gi, "")
                }
                contentDisposition="auto"
              />
            </label>
          )}
          <a
            className="btn secondary-btn webcam-component btn-take-selfie"
            onClick={toggleWebcam}
            style={{
              backgroundColor: `${pageConfiguration.secondaryColor}`
            }}
          >
            <SelfieIcon
              color={ContrastForegroundUtility.fgColor(
                pageConfiguration.secondaryColor
              )}
            />
            <span
              style={{
                color: `${ContrastForegroundUtility.fgColor(
                  pageConfiguration.secondaryColor
                )}`
              }}
            >
              Take a selfie
            </span>
          </a>
        </div>
      </div>
      {!previewImageState && (
        <Fragment>
          <div>
            <ImageUploadAlert />
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default UploadSelfie;
