import React from "react";
import PropTypes from "prop-types";
// @ts-ignore
import videojs from "@mux/videojs-kit";
const hiddenIcon = require("../../styles/imgHide.svg");
// TODO extract to separate file, or load from CDN
import "@mux/videojs-kit/dist/index.css";
import { Event } from "../types";
import Favorite, { OnClickFavIconProps } from "./Favorite";

interface LightBoxVideoProps {
  video: {
    videoContent: boolean;
    videoMuxPlaybackId: string;
    videoStartTime: string;
    hidden: boolean;
    isFavorite: boolean;
    id: number;
  };
  event: Event;
  hidden: boolean;
  onClickFavoriteIcon: ({ id, isFavorite }: OnClickFavIconProps) => void;
  showFavIcon?: boolean;
}

interface LightBoxVideoState {
  overlayImageSize: number;
  videoId: string;
}
class LightBoxVideo extends React.Component<
  LightBoxVideoProps,
  LightBoxVideoState
> {
  videoPlayerRef: React.RefObject<unknown>;
  constructor(props: LightBoxVideoProps | Readonly<LightBoxVideoProps>) {
    super(props);
    this.videoPlayerRef = React.createRef();
    this.state = {
      overlayImageSize: 0,
      videoId: this.props.video.videoMuxPlaybackId
    };
  }

  componentDidMount() {
    videojs(this.videoPlayerRef.current).currentTime(
      parseInt(this.props.video.videoStartTime)
    );
    videojs(this.videoPlayerRef.current).play();
  }

  shouldComponentUpdate(
    nextProps: { video: { videoMuxPlaybackId: any; isFavorite: boolean } },
    nextState: any
  ) {
    if (this.state.videoId != nextProps.video.videoMuxPlaybackId) {
      return true;
    } else if (this.props.video.isFavorite != nextProps.video.isFavorite) {
      return true;
    } else return false;
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.video.videoMuxPlaybackId !== this.state.videoId) {
      this.setState({
        videoId: this.props.video.videoMuxPlaybackId
      });
    }
  }

  render() {
    return (
      <div className="dv-imgdetails" style={{ height: "100%", width: "100%" }}>
        {this.props.hidden && (
          <span className="geo-visibility-stamp">
            <img src={hiddenIcon} />
          </span>
        )}
        <video
          id="my-player"
          className="video-js vjs-16-9"
          controls
          //@ts-ignore
          ref={this.videoPlayerRef}
          preload="auto"
          width="100%"
          data-setup="{}"
          muted
          disablePictureInPicture
        >
          <source src={this.state.videoId} type="video/mux" />
        </video>
        {this.props.showFavIcon && (
          <Favorite
            onClickFavoriteIcon={this.props.onClickFavoriteIcon}
            isFavorite={this.props.video.isFavorite}
            id={this.props.video.id}
            source="lightbox"
          />
        )}
      </div>
    );
  }
}

export default LightBoxVideo;
