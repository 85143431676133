import React from "react";

const HideCancel = (props: { color: string | undefined }) => {
  return (
    <svg
      width="45"
      height="45"
      viewBox="0 0 45 45"
      fill="transparent"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.8826 11.8931C23.1577 11.1609 21.2976 10.78 19.4119 10.78C16.9392 10.78 14.5106 11.4349 12.3732 12.6781C10.2357 13.9212 8.46568 15.7084 7.24306 17.8576C7.08374 18.1389 7 18.4566 7 18.7799C7 19.1031 7.08374 19.4208 7.24306 19.7021C8.46568 21.8514 10.2357 23.6385 12.3732 24.8817C12.9259 25.2031 13.4981 25.4853 14.0854 25.7269L16.1957 23.0232C15.8626 22.7717 15.5587 22.4808 15.291 22.1554C14.6589 21.3871 14.2543 20.4572 14.123 19.471C14.0166 18.6602 14.098 17.8358 14.361 17.0615C14.624 16.2872 15.0615 15.5837 15.6397 15.0055C16.218 14.4273 16.9214 13.9897 17.6957 13.7267C18.47 13.4637 19.2944 13.3823 20.1052 13.4888L20.1097 13.4799C21.0801 13.6084 21.9962 14.0014 22.7577 14.6156L24.8826 11.8931ZM21.3987 16.3568C20.8645 15.9251 20.1846 15.6665 19.4444 15.6665C17.7261 15.6665 16.3333 17.0594 16.3333 18.7776C16.3333 19.7923 16.819 20.6936 17.5707 21.2615L21.3987 16.3568ZM22.519 19.2556L20.6543 21.6447C21.6306 21.2322 22.3525 20.3355 22.519 19.2556ZM18.7679 24.0616C19.385 24.1364 20.0133 24.1031 20.6241 23.9603C21.5929 23.7337 22.479 23.2407 23.1823 22.5369C23.8856 21.8331 24.378 20.9466 24.6038 19.9776C24.8296 19.0087 24.7799 17.9958 24.4603 17.0536C24.438 16.9881 24.4145 16.923 24.3898 16.8586L27.2572 13.1847C29.0346 14.3872 30.5155 15.985 31.5807 17.8576C31.7401 18.1389 31.8238 18.4566 31.8238 18.7799C31.8238 19.1031 31.7401 19.4208 31.5807 19.7021C30.3581 21.8514 28.5881 23.6385 26.4506 24.8817C24.3132 26.1249 21.8846 26.7798 19.4119 26.7798C18.5434 26.7798 17.6803 26.699 16.8334 26.5403L18.7679 24.0616Z"
        fill={props.color}
        style={{ transform: "translate(4px, 4px)" }}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.1014 9.1882C27.4884 9.49025 27.5572 10.0488 27.2552 10.4358L13.033 28.658C12.731 29.045 12.1724 29.1138 11.7854 28.8118C11.3984 28.5098 11.3295 27.9512 11.6316 27.5642L25.8537 9.34202C26.1558 8.95502 26.7144 8.88616 27.1014 9.1882Z"
        fill={props.color}
        style={{ transform: "translate(4px, 4px)" }}
      />
      <circle cx="22.5" cy="22.5" r="22" stroke={props.color} />
    </svg>
  );
};
export default HideCancel;
