import React from "react";
export type SelfieSearchContextType = {
  adminUser: boolean;
  selfieSearchS3Bucket: string;
  currentUserId: number | null;
  currentUserCountry: string;
  openSelfieUploadPopup: () => void;
  referenceImage: string | null;
  pageConfiguration: {
    primaryColor: string;
    secondaryColor: string;
    accentColor: string;
  };
};

export const SelfieSearchContext = React.createContext<SelfieSearchContextType>(
  {
    adminUser: false,
    selfieSearchS3Bucket: "",
    currentUserId: null,
    currentUserCountry: "",
    openSelfieUploadPopup: () => null,
    referenceImage: null,
    pageConfiguration: { primaryColor: "", secondaryColor: "", accentColor: "" }
  }
);
