const Telemetry = {
  track: function (event, properties) {
    if (window.rudderanalytics) {
      window.rudderanalytics.track(event, properties);
    } else {
      console.log("Skipped sending RS event:", event, properties);
    }
  },
  page: function (name, properties) {
    if (window.rudderanalytics) {
      window.rudderanalytics.page(name, properties);
    } else {
      console.log("Skipped logging RS page view:", name, properties);
    }
  }
};

export default Telemetry;
