import React from "react";

export const CheckOutline = () => {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5 23C17.8513 23 23 17.8513 23 11.5C23 5.14873 17.8513 0 11.5 0C5.14873 0 0 5.14873 0 11.5C0 17.8513 5.14873 23 11.5 23ZM19.7676 8.70998C20.1928 8.28473 20.1928 7.59526 19.7676 7.17001C19.3423 6.74476 18.6528 6.74476 18.2276 7.17001L9.8902 15.5074L5.34756 10.9648C4.92231 10.5395 4.23284 10.5395 3.80759 10.9648C3.38233 11.39 3.38233 12.0795 3.80759 12.5047L9.12022 17.8174C9.54547 18.2426 10.2349 18.2426 10.6602 17.8174L19.7676 8.70998Z"
        fill="#FCFCFC"
        fillOpacity="0.6"
      />
    </svg>
  );
};

export const CheckFilled = (props: { color: string }) => (
  <svg
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="11.5" cy="11.5" r="11.5" fill={props.color} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.7704 7.17451C20.1957 7.59991 20.1957 8.28961 19.7704 8.715L10.6599 17.8254C10.2345 18.2508 9.54482 18.2508 9.11943 17.8254L3.80501 12.511C3.37961 12.0856 3.37961 11.3959 3.80501 10.9705C4.2304 10.5451 4.9201 10.5451 5.3455 10.9705L9.88967 15.5147L18.2299 7.17451C18.6553 6.74912 19.345 6.74912 19.7704 7.17451Z"
      fill="white"
    />
  </svg>
);
