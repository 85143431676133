const locale = "en-US";

function dateFormatter(date: Date, pattern: string): string {
  switch (pattern) {
    case "MMM DD, YYYY":
      return date.toLocaleDateString(locale, {
        year: "numeric",
        month: "short",
        day: "numeric"
      });
      break;

    case "MMMM DD, YYYY, dddd":
      return date.toLocaleDateString(locale, {
        year: "numeric",
        month: "long",
        day: "2-digit",
        weekday: "long"
      });
    case "D":
      return date.toLocaleDateString(locale, { day: "numeric" });

    case "MMM":
      return date.toLocaleDateString(locale, { month: "short" });

    case "YYYY":
      return date.toLocaleDateString(locale, { year: "numeric" });

    default:
      return "";
      break;
  }
}

export function moment(d: any) {
  const date = new Date(d);
  return {
    format: (pattern: string) => dateFormatter(date, pattern),

    isValid: () => { // called from millisecondsTohms. d is a number
      return !isNaN(Date.parse(d));
    }
  };
}
