import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import PrecacheImg from "../../../../utils/PrecacheImage";
import {
  withCartIdParam,
  addParamToURL
} from "../../../../utils/BrowserHelper";
import Telemetry from "../../../../utils/rudderstackAnalytics";
import LightBoxImage from "./LightBoxImage";
import keydown from "react-keydown";
import Swipeable from "react-swipeable";
import SaveIcon from "../vectors/SaveIcon";
import ajax from "superagent";
import LightBoxVideo from "./LightBoxVideo";
import { Album as AlbumType, Event } from "../types";
import TagsSection from "./TagsSections";
import HideIcon from "../vectors/HideIcon";
import HideCancel from "../vectors/HideCancel";
import {
  LightBoxCartFilled,
  LightBoxCartOutline,
  LightBoxViewCart
} from "../vectors/AddToCartIcon";
import { OnClickFavIconProps } from "./Favorite";
import ISOToDateTimeFormat from "../../../../utils/ISOToDateTimeFormat";
import ContrastForegroundUtility from "../../../../utils/ContrastForegroundUtility";

interface LightBoxProps {
  photos: any;
  photoIndex: number;
  showLightBox: Function;
  closeLightBox: Function;
  cartHasPhotos?: boolean;
  addToCart: Function;
  removeFromCart: Function;
  event: Event;
  album: AlbumType;
  eventClickable?: boolean;
  cartId?: number | null;
  isAuthorizedForDirectDownloads?: boolean;
  primaryColor?: string;
  userId?: number;
  isEmbed?: boolean;
  photoSaved?: boolean;
  curationApiCalled?: boolean;
  setCurationApiCalled: Function;
  onClickFavoriteIcon: ({ id, isFavorite }: OnClickFavIconProps) => void;
  trackTelemetry?: boolean;
  parentName?: string;
  showFavIcon?: boolean;
}

interface LightBoxState {
  photo: any;
  photoSaved: boolean;
  addTags: boolean;
  mediaTags: Array<{ name: string; tagId: number; taggableType: string }>;
  loading: boolean;
  defaultHidden: boolean;
}

class LightBox extends React.Component<LightBoxProps, LightBoxState> {
  static contextTypes = {
    prepaidCustomer: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      email: PropTypes.string,
      eventId: PropTypes.number
    }),
    cartId: PropTypes.number,
    isEmbed: PropTypes.bool,
    userId: PropTypes.number,
    pageConfiguration: PropTypes.shape({
      primaryColor: PropTypes.string,
      secondaryColor: PropTypes.string,
      accentColor: PropTypes.string
    }),
    arePrepaidPhotosAvailable: PropTypes.any,
    organizationId: PropTypes.number,
    canManageTags: PropTypes.bool,
    reLoadAlbumPhotos: PropTypes.func,
    curationMode: PropTypes.bool,
    isAuthorizedForDirectDownloads: PropTypes.bool
  };

  constructor(props: LightBoxProps) {
    super(props);

    this.state = {
      photo: this.props.photos[this.props.photoIndex],
      photoSaved: false,
      addTags: false,
      mediaTags: [],
      loading: true,
      defaultHidden: this.props.photos[this.props.photoIndex].hidden
    };
  }

  canDownloadPhotos = () =>
    this.props.isAuthorizedForDirectDownloads ||
    this.context.isAuthorizedForDirectDownloads;

  componentDidMount() {
    this.canDownloadPhotos() && this.verifyPhotoSaved();
    if (this.context.curationMode) {
      this.verifyPhotoHidden();
    }
    if (this.context.canManageTags) {
      this.getTags();
    } else {
      this.setState({ loading: false });
    }
  }

  shouldComponentUpdate(
    nextProps: { photos: { [x: string]: any }; photoIndex: string | number },
    nextState: { photoSaved: boolean; loading: boolean; defaultHidden: boolean }
  ) {
    if (
      this.state.photo != nextProps.photos[nextProps.photoIndex] ||
      this.state.photoSaved != nextState.photoSaved ||
      this.state.loading != nextState.loading ||
      this.state.defaultHidden != nextState.defaultHidden
    ) {
      return true;
    } else return false;
  }

  componentDidUpdate() {
    if (this.state.photo === this.props.photos[this.props.photoIndex]) {
      this.setState({ photo: this.props.photos[this.props.photoIndex] });
    }
  }

  setHiddenState = (val: boolean) => this.setState({ defaultHidden: val });

  verifyPhotoHidden = () =>
    ajax
      .get(
        `/api/v1/photos/${
          this.props.photos[this.props.photoIndex].id
        }/is_hidden?user_id=${this.context.userId || this.props.userId}`
      )
      .then(response => {
        this.setState({ defaultHidden: response.body.is_hidden });
      });

  verifyPhotoSaved = () =>
    ajax
      .get(
        `/api/v1/photos/${
          this.props.photos[this.props.photoIndex].id
        }/is_saved?user_id=${this.context.userId || this.props.userId}`
      )
      .then(response => {
        this.setState({ photoSaved: response.body.is_saved });
      });

  handleSave = () => {
    ajax
      .post(
        addParamToURL(
          withCartIdParam(
            `/api/v1/photos/${this.state.photo.id}/save`,
            this.cartId(),
            this.isEmbed()
          ),
          "user_id",
          this.context.userId || this.props.userId
        )
      )
      .then(res => {
        this.setState({ photoSaved: true }, () =>
          //@ts-ignore
          toastr.success("Photo saved", null, { timeOut: 1000 })
        );

        return true;
      });
  };

  getTags = () => {
    ajax
      .get(
        `/api/v1/content_tags/${this.props.photos[this.props.photoIndex].id}`
      )
      .then(response =>
        this.setState({ mediaTags: response.body.appliedTags, loading: false })
      )
      .catch(error => {
        // @ts-ignore
        toastr.error("There was an error loading tags");
        console.error(error);
        this.setState({ loading: false });
      });
  };

  handleUnsave = () => {
    ajax
      .delete(
        addParamToURL(
          withCartIdParam(
            `/api/v1/photos/${this.state.photo.id}/unsave`,
            this.cartId()
          ),
          "user_id",
          this.context.userId || this.props.userId
        )
      )
      .then(res => {
        this.setState({ photoSaved: false }, () =>
          //@ts-ignore
          toastr.success("Photo unsaved", null, { timeOut: 1000 })
        );

        return true;
      });
  };

  componentWillMount() {
    document.getElementsByTagName("body")[0].classList.add("modal-open");
  }

  componentWillUnmount() {
    document.getElementsByTagName("body")[0].classList.remove("modal-open");
  }

  @keydown("left", "right", "esc")
  submit(event: { which: number }) {
    if (event.which == 37 && this.hasPrevImage()) this.prevImage();
    else if (event.which == 39 && this.hasNextImage()) this.nextImage();
    else if (event.which == 27) this.props.closeLightBox();
  }

  componentWillReceiveProps(nextProps: {
    photos: { [x: string]: any };
    photoIndex: string | number;
  }) {
    this.setState({
      photo: nextProps.photos[nextProps.photoIndex]
    });
  }

  // returns next three photos' watermarked & zoom versions
  // as an array
  imagesToPrefetch = () =>
    _.compact(
      _.flatten(
        _.slice(
          this.props.photos,
          this.props.photoIndex,
          this.props.photoIndex + 3
        ).map((photo: any) => {
          return [photo.watermarkedImg, photo.zoomImg];
        })
      )
    );

  prevImage = async (e?: any) => {
    if (e) e.preventDefault();
    this.resetLightBoxImage(this.props.photoIndex - 1);
    if (this.context.curationMode) {
      this.verifyPhotoHidden();
    }
  };

  nextImage = async (e?: any) => {
    if (e) e.preventDefault();
    this.resetLightBoxImage(this.props.photoIndex + 1);
    if (this.context.curationMode) {
      this.verifyPhotoHidden();
    }
  };

  resetLightBoxImage = (photoIndex: number) => {
    this.props.showLightBox(photoIndex);
  };

  hasPrevImage = () => {
    return this.props.photos[this.props.photoIndex - 1] != undefined;
  };

  hasNextImage = () => {
    return this.props.photos[this.props.photoIndex + 1] != undefined;
  };

  leftArrow = () => {
    return (
      <a className="left carousel-control" onClick={this.prevImage}>
        <span className="fa fa-angle-left" />
      </a>
    );
  };

  rightArrow = () => {
    return (
      <a className="right carousel-control" onClick={this.nextImage}>
        <span className="fa fa-angle-right" />
      </a>
    );
  };

  cartId = () => this.context.cartId || this.props.cartId;
  isEmbed = () => this.context.isEmbed || this.props.isEmbed;

  handleCloseLightBox = () => (
    this.props.curationApiCalled && this.props.setCurationApiCalled(false),
    this.props.closeLightBox(),
    this.context.curationMode &&
      this.props.curationApiCalled &&
      this.context.reLoadAlbumPhotos()
  );

  curatePhoto = async () => {
    const hiddenVal = this.props.curationApiCalled
      ? this.state.defaultHidden
      : this.state.photo.hidden;
    const action = hiddenVal == true ? "unhide" : "hide";
    try {
      const res = await $.ajax({
        url: `/api/v1/photos/${action}`,
        type: "POST",
        data: {
          photo_ids: [this.state.photo.id],
          event_id: this.props.event.id
        }
      });

      //@ts-ignore
      toastr.success("Successfully updated");
      this.setState({
        defaultHidden: !hiddenVal
      });
    } catch (err) {
      //@ts-ignore
      toastr.error("Failed to update");
      console.error(err);
    }
  };

  render() {
    return (
      <span>
        <div className="modal fade in ns" id="modal-mygallery">
          {this.state.loading ? (
            <div>loading...</div>
          ) : (
            <Swipeable
              onSwipedLeft={this.nextImage}
              onSwipedRight={this.prevImage}
            >
              <div className="modal-dialog" role="document">
                <div className="close-modal">
                  <button
                    type="button"
                    className="close"
                    aria-label="Close"
                    onClick={() => this.handleCloseLightBox()}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-content">
                  <div className="modal-header">
                    <div className="modal-mygallery-ttl">
                      <div className="hidden-xs">
                        <LightBoxHeader
                          event={this.props.event}
                          album={this.props.album}
                          photo={this.state.photo}
                          eventClickable={this.props.eventClickable}
                          cartId={this.cartId()}
                          isEmbed={this.isEmbed()}
                        />
                      </div>
                      <div className="mobile-header visible-xs-block">
                        <LightBoxFooter
                          event={this.props.event}
                          album={this.props.album}
                          photo={this.state.photo}
                          photoHidden={this.state.defaultHidden}
                          cartHasPhotos={this.props.cartHasPhotos}
                          addToCart={this.props.addToCart}
                          removeFromCart={this.props.removeFromCart}
                          cartId={this.cartId()}
                          isEmbed={this.isEmbed()}
                          primaryColor={
                            this.props.primaryColor ||
                            this.context.pageConfiguration.primaryColor ||
                            "#259fa5"
                          }
                          userId={this.props.userId}
                          photoSaved={this.state.photoSaved}
                          handleSave={this.handleSave}
                          handleUnsave={this.handleUnsave}
                          setHiddenState={this.setHiddenState}
                          setCurationApiCalled={this.props.setCurationApiCalled}
                          curatePhoto={this.curatePhoto}
                          trackTelemetry={this.props.trackTelemetry}
                          parentName={this.props.parentName}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="modal-body">
                    {this.state.photo.videoContent ? (
                      <LightBoxVideo
                        video={this.state.photo}
                        hidden={this.state.defaultHidden}
                        //@ts-ignore
                        event={this.props.event}
                        onClickFavoriteIcon={this.props.onClickFavoriteIcon}
                        showFavIcon={this.props.showFavIcon}
                      />
                    ) : (
                      <LightBoxImage
                        photo={this.state.photo}
                        hidden={this.state.defaultHidden}
                        //@ts-ignore
                        event={this.props.event}
                        onClickFavoriteIcon={this.props.onClickFavoriteIcon}
                        showFavIcon={this.props.showFavIcon}
                      />
                    )}
                  </div>
                  <div className="modal-footer hidden-xs">
                    <LightBoxFooter
                      event={this.props.event}
                      album={this.props.album}
                      photo={this.state.photo}
                      photoHidden={this.state.defaultHidden}
                      cartHasPhotos={this.props.cartHasPhotos}
                      addToCart={this.props.addToCart}
                      removeFromCart={this.props.removeFromCart}
                      cartId={this.cartId()}
                      isEmbed={this.isEmbed()}
                      isAuthorizedForDirectDownloads={
                        this.props.isAuthorizedForDirectDownloads
                      }
                      primaryColor={
                        this.props.primaryColor ||
                        this.context.pageConfiguration.primaryColor ||
                        "#259fa5"
                      }
                      userId={this.props.userId}
                      photoSaved={this.state.photoSaved}
                      handleSave={this.handleSave}
                      handleUnsave={this.handleUnsave}
                      setHiddenState={this.setHiddenState}
                      setCurationApiCalled={this.props.setCurationApiCalled}
                      curatePhoto={this.curatePhoto}
                      trackTelemetry={this.props.trackTelemetry}
                      parentName={this.props.parentName}
                    />
                  </div>
                  {this.context.canManageTags && (
                    <TagsSection
                      colorStyling={
                        this.props.primaryColor ||
                        this.context.pageConfiguration.primaryColor ||
                        "#259fa5"
                      }
                      appliedTags={this.state.mediaTags}
                      photoId={[this.state.photo.id]}
                      contentTagsAPI={"/api/v1/content_tags"}
                    />
                  )}
                </div>
              </div>
            </Swipeable>
          )}

          {this.hasPrevImage() ? this.leftArrow() : null}
          {this.hasNextImage() ? this.rightArrow() : null}
          <PrecacheImg images={this.imagesToPrefetch()} />
        </div>
        <div className="modal-backdrop fade in" />
      </span>
    );
  }
}

export default LightBox;

interface LightBoxFooterProps {
  event: Event;
  album: AlbumType;
  photo: any;
  cartHasPhotos?: boolean;
  addToCart: Function;
  removeFromCart: Function;
  cartId: Function;
  isEmbed: Function;
  isAuthorizedForDirectDownloads?: boolean;
  primaryColor: string;
  userId?: number;
  photoSaved: any;
  handleSave: Function;
  handleUnsave: Function;
  photoHidden: boolean;
  setHiddenState: Function;
  setCurationApiCalled: Function;
  curatePhoto: Function;
  trackTelemetry?: boolean;
  parentName?: string;
}
class LightBoxFooter extends React.Component<LightBoxFooterProps> {
  static contextTypes = {
    isAuthorizedForDirectDownloads: PropTypes.bool,
    prepaidCustomer: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      email: PropTypes.string,
      eventId: PropTypes.number
    }),
    canManageTags: PropTypes.bool,
    cartPrepaidPhotos: PropTypes.array,
    arePrepaidPhotosAvailable: PropTypes.any,
    cartId: PropTypes.number,
    isEmbed: PropTypes.bool,
    userId: PropTypes.number,
    pageConfiguration: PropTypes.shape({
      primaryColor: PropTypes.string,
      secondaryColor: PropTypes.string,
      accentColor: PropTypes.string
    }),
    curationMode: PropTypes.bool
  };

  cartId = () => this.context.cartId || this.props.cartId;
  isEmbed = () => this.context.isEmbed || this.props.isEmbed;
  canDownloadPhotos = () =>
    this.props.isAuthorizedForDirectDownloads ||
    this.context.isAuthorizedForDirectDownloads;

  freeEvent = () => this.props.event.price == 0;

  isPrepaid = () =>
    (this.context.arePrepaidPhotosAvailable
      ? this.context.arePrepaidPhotosAvailable()
      : false) ||
    _.includes(this.context.cartPrepaidPhotos, this.props.photo.id);

  handleHideTooltip = () =>
    //@ts-ignore
    $('#save-tooltip [data-toggle="tooltip"]').tooltip();

  handleToggle = (id: string, msg: string) =>
    $(`#${id} [data-toggle="tooltip"]`)
      .attr("data-original-title", `${msg}`)
      //@ts-ignore
      .tooltip("show");

  toggleSave = () =>
    this.props.photoSaved
      ? (this.props.handleUnsave(),
        $('#save-tooltip [data-toggle="tooltip"]')
          .attr("data-original-title", "Save for later")
          //@ts-ignore
          .tooltip("show"))
      : (this.props.handleSave(),
        //@ts-ignore
        $('#save-tooltip [data-toggle="tooltip"]').tooltip("hide"));

  handlePhotoNameTooltip = () =>
    //@ts-ignore
    $(".geo-lt__photoname").tooltip();

  render() {
    return (
      <React.Fragment>
        <div className="modal-footer-details">
          {this.freeEvent() ? (
            <h3 style={{ textTransform: "uppercase" }}>Free</h3>
          ) : this.isPrepaid() ? (
            <div className="prepaid">
              <del>{this.props.event.displayPrice} </del>
              <span className="currency">{this.props.event.currency}</span>
              <span className="free"> Free </span>
            </div>
          ) : (
            <h3>
              {this.props.event.displayPrice}{" "}
              <span>{this.props.event.currency}</span>
            </h3>
          )}
          <p className="geo-lt__info">*for High quality digital download</p>
        </div>
        <div className="modal-footer-actions eg-lt-footer">
          {this.props.cartHasPhotos ? (
            <a
              href={withCartIdParam(
                `/checkout?source=${this.props.parentName}`,
                this.cartId(),
                this.isEmbed()
              )}
              target={this.isEmbed() ? "_blank" : ""}
              className="geo-btn geo-btn--secondary geo-btn--caps geo-btn--cart-icon"
              style={{
                backgroundColor: ContrastForegroundUtility.lightenColor(
                  this.props.primaryColor
                )
              }}
            >
              <LightBoxViewCart
                color={ContrastForegroundUtility.lbFgColor(
                  this.props.primaryColor
                )}
              />
              <span
                style={{
                  color: ContrastForegroundUtility.lbFgColor(
                    this.props.primaryColor
                  )
                }}
              >
                &nbsp; View cart
              </span>
            </a>
          ) : null}
          {this.props.photo.isSelected ? (
            <div
              className="geo-lt__cart-icon geo-btn--caps"
              onClick={e => {
                e.preventDefault();
                this.props.removeFromCart(this.props.photo);
                if (this.props.trackTelemetry) {
                  Telemetry.track("event-gallery:remove-from-cart", {
                    eventId: this.props.event.id,
                    eventName: this.props.event.name,
                    photoId: this.props.photo.id,
                    cartId: this.context.cartId,
                    source: "lightbox",
                    embeddedMode: this.context.isEmbed
                  });
                }
              }}
              style={{
                border: `1px solid ${ContrastForegroundUtility.lbFgColor(
                  this.props.primaryColor
                )}`,
                color: ContrastForegroundUtility.lbFgColor(
                  this.props.primaryColor
                )
              }}
            >
              <LightBoxCartFilled
                color={ContrastForegroundUtility.lbFgColor(
                  this.props.primaryColor
                )}
              />
              <span>&nbsp; Remove</span>
            </div>
          ) : (
            <div
              className="geo-lt__cart-icon geo-btn--caps"
              onClick={e => {
                e.preventDefault();
                this.props.addToCart(this.props.photo);
                if (this.props.trackTelemetry) {
                  Telemetry.track("event-gallery:add-to-cart", {
                    eventId: this.props.event.id,
                    eventName: this.props.event.name,
                    photoId: this.props.photo.id,
                    cartId: this.context.cartId,
                    source: "lightbox",
                    embeddedMode: this.context.isEmbed
                  });
                }
              }}
              style={{
                background: ContrastForegroundUtility.lbFgColor(
                  this.props.primaryColor
                ),
                borderColor: ContrastForegroundUtility.lbFgColor(
                  this.props.primaryColor
                ),
                color: "white"
              }}
            >
              <LightBoxCartOutline color={"white"} />
              <span>&nbsp; Add to cart</span>
            </div>
          )}
          {this.context.curationMode && (
            <div id="hide-btn">
              <button
                data-toggle="tooltip"
                data-placement="bottom"
                onMouseEnter={() =>
                  this.handleToggle(
                    "hide-btn",
                    this.props.photoHidden ? "Unhide Photo" : "Hide Photo"
                  )
                }
                onMouseLeave={() => this.handleHideTooltip()}
                className="geo-icon-btn-styling"
                onClick={() => (
                  this.props.curatePhoto(),
                  this.props.setCurationApiCalled(true)
                )}
              >
                {this.props.photoHidden ? (
                  <HideIcon
                    color={
                      this.props.primaryColor ||
                      this.context.pageConfiguration.primaryColor
                    }
                  />
                ) : (
                  <HideCancel
                    color={
                      this.props.primaryColor ||
                      this.context.pageConfiguration.primaryColor
                    }
                  />
                )}
              </button>
            </div>
          )}
          {this.canDownloadPhotos() ? (
            <div className="download-save-btns" id="save-tooltip">
              <button
                data-toggle="tooltip"
                data-placement="bottom"
                onMouseEnter={() =>
                  this.handleToggle(
                    "save-tooltip",
                    this.props.photoSaved ? "Unsave Photo" : "Save for later"
                  )
                }
                onMouseLeave={() => this.handleHideTooltip()}
                className="save-btn"
                onClick={() => this.toggleSave()}
                style={{
                  border: `1px solid ${
                    this.props.photoSaved
                      ? "gray"
                      : ContrastForegroundUtility.lbFgColor(
                          this.props.primaryColor
                        )
                  }`
                }}
              >
                <SaveIcon
                  color={
                    this.props.photoSaved
                      ? "gray"
                      : ContrastForegroundUtility.lbFgColor(
                          this.props.primaryColor
                        )
                  }
                />
              </button>
              <a
                href={addParamToURL(
                  withCartIdParam(
                    `/api/v1/photos/${this.props.photo.id}/download`,
                    this.cartId(),
                    this.isEmbed()
                  ),
                  "user_id",
                  this.context.userId
                )}
                target="_blank"
                className="geo-btn geo-btn--secondary geo-btn--caps"
                style={{
                  backgroundColor: ContrastForegroundUtility.lbFgColor(
                    this.props.primaryColor
                  ),
                  height: 44,
                  width: 44
                }}
              >
                <span
                  className="fa fa-download"
                  style={{
                    color: "white"
                  }}
                />
              </a>
            </div>
          ) : null}
        </div>
      </React.Fragment>
    );
  }
}

interface LightBoxHeaderProps {
  event: Event;
  album: AlbumType;
  photo: any;
  eventClickable?: boolean;
  cartId: number;
  isEmbed: Function;
}

class LightBoxHeader extends React.Component<LightBoxHeaderProps> {
  static contextTypes = {
    cartId: PropTypes.number,
    isEmbed: PropTypes.bool,
    hidePhotographersFilter: PropTypes.bool
  };

  cartId = () => this.context.cartId || this.props.cartId;
  isEmbed = () => this.context.isEmbed || this.props.isEmbed;
  hidePhotographersFilter = () =>
    this.context.hidePhotographersFilter ||
    this.props.photo.hidePhotographersFilter;

  render() {
    return (
      <div className="dv-imgdetails-text">
        <a
          href={
            this.props.eventClickable
              ? withCartIdParam(
                  this.props.event.browsePhotosPath,
                  this.cartId(),
                  this.isEmbed()
                )
              : "#"
          }
          //@ts-ignore
          target={this.props.eventClickable ? "_blank" : null}
        >
          {this.props.event.name}
        </a>
        <h4>{this.props.album.name}</h4>
        <div className="geo-report__wrap d-flex geo-jcb geo-ac">
          <p>
            {!this.hidePhotographersFilter() && (
              <>
                {this.props.album.photographerName}{" "}
                {this.props.photo.dateTimeTaken && "•"}
              </>
            )}
            <p style={{ textTransform: "none" }}>
              {this.props.photo.dateTimeTaken
                ? ` ${ISOToDateTimeFormat(this.props.photo.dateTimeTaken)}`
                : ""}
            </p>
          </p>
          <a
            target="_blank"
            className="geo-report__btn"
            href={this.props.photo.reportPhotoPath}
          >
            <i className="fa fa-flag"></i>
            <u>Report Content</u>
          </a>
        </div>
      </div>
    );
  }
}
