const getCookie = (name: string) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2)
    // @ts-ignore
    return parts.pop().split(";").shift();
  else return null;
};

const setCookie = (name: string, value: string) => {
  deleteCookie(name);
  const date = new Date();
  date.setHours(date.getHours() + 1);
  document.cookie = `${name}=${value}; expires=${date}; path=/;`;
};

const deleteCookie = (name: string) => {
  const date = new Date();
  date.setHours(date.getHours() - 1);
  document.cookie = `${name}=; expires=${date}; path=/;`;
};

const getParameterByName = (paramName: string) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(paramName);
};
const removeParam = (key: any, sourceURL: any) => {
  let rtn = sourceURL.split("?")[0];
  let param;
  let paramsArr = [];
  const queryString =
    sourceURL.indexOf("?") !== -1 ? sourceURL.split("?")[1] : "";
  if (queryString !== "") {
    paramsArr = queryString.split("&");
    for (let i = paramsArr.length - 1; i >= 0; i -= 1) {
      param = paramsArr[i].split("=")[0];
      if (param === key) {
        paramsArr.splice(i, 1);
      }
    }
    rtn = rtn + "?" + paramsArr.join("&");
  }
  window.history.pushState("", "", rtn);
};

const addParam = (key: string, value: any) => {
  const queryParams = new URLSearchParams(window.location.search).toString();
  window.history.pushState(
    {},
    "",
    queryParams === "" ? `?${key}=${value}` : `?${queryParams}&${key}=${value}`
  );
};

const addParamToURL = (url: string, key: string, value: any) => {
  url += (url.split("?")[1] ? "&" : "?") + `${key}=${value}`;
  return url;
};

const withCartIdParam = (
  url: string,
  cartId: number | null,
  isEmbed: boolean = false
) => {
  let newURL = url;
  if (cartId) newURL = addParamToURL(newURL, "cart_id", cartId);
  if (isEmbed) newURL = addParamToURL(newURL, "embed", isEmbed);

  // @ts-ignore
  if (window.rudderanalytics && window.rudderanalytics.getAnonymousId)
    newURL = addParamToURL(
      newURL,
      "telemetry_anonymous_id",
      // @ts-ignore
      window.rudderanalytics.getAnonymousId()
    );

  return newURL;
};

const isSafari =
  navigator.vendor &&
  navigator.vendor.indexOf("Apple") > -1 &&
  navigator.userAgent &&
  navigator.userAgent.indexOf("CriOS") == -1 &&
  navigator.userAgent.indexOf("FxiOS") == -1;

export {
  getCookie,
  setCookie,
  getParameterByName,
  removeParam,
  addParam,
  deleteCookie,
  addParamToURL,
  withCartIdParam,
  isSafari
};
