import { PropTypes } from "prop-types";
import React, { Fragment } from "react";

const PrecacheImg = props => {
  const precache = images => {
    let image;
    for (let i = 0, len = images.length; i < len; i += 1) {
      image = new Image(); // eslint-disable-line no-undef
      image.src = images[i];
    }
    return false;
  };

  return <Fragment>{precache(props.images)}</Fragment>;
};

PrecacheImg.propTypes = {
  images: PropTypes.array.isRequired
};

export default PrecacheImg;
