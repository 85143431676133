import React, { useState, useContext, useEffect } from "react";
import ajax from "superagent";
import { SelfieSearchContext } from "../SelfieSearchContext";
import UploadSelfie from "./UploadSelfie";
import WebcamCapture from "./WebcamCapture";

const SelfieUploadPopup = ({
  updateImageFilter,
  closePopup,
  showReelPage,
  hideUploadSelfie
}: {
  updateImageFilter: (imageUrl: string, clearSelfie: boolean) => void;
  closePopup: () => void;
  showReelPage?: boolean;
  hideUploadSelfie: boolean;
}) => {
  const [webcam, setWebcam] = useState(false);
  const [imageURL, setImageURL] = useState("");
  const context = useContext(SelfieSearchContext);
  const [previewImage, setPreviewImage] = useState(context.referenceImage);

  useEffect(() => {
    document.getElementsByTagName("body")[0].classList.add("modal-open");
    return () => {
      document.getElementsByTagName("body")[0].classList.remove("modal-open");
    };
  }, []);

  useEffect(() => {
    setPreviewImage(context.referenceImage);
  }, [context.referenceImage]);
  const uploadAndFilter = (imageFile: File) => {
    setPreviewImage(URL.createObjectURL(imageFile));
    // @ts-ignore
    (window.dataLayer || []).push({
      event: "filterAlbumByImage"
    });
    ajax
      .post("/s3/upload_image")
      .attach("file", imageFile)
      .field("bucket", context.selfieSearchS3Bucket)
      .then(response => {
        setImageURL(response.body.url);
        updateImageFilter(response.body.url, false);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const uploadBase64Image = (base64Img: string) => {
    fetch(base64Img)
      .then(res => res.blob())
      .then(blob => {
        // changed
        uploadAndFilter(new File([blob], "image/png"));
      })
      .catch(error => {
        console.log(error);
      });
  };

  const toggleWebcam = () => {
    setWebcam(!webcam);
  };

  return (
    <div>
      <div
        id="filter-dialog"
        className={`modal fade in ns geo-modal geo-modal--scroll master-search-popup ${
          webcam ? "ns-modal-large" : "geo-selfie-popup"
        }`}
        role="dialog"
      >
        <div className="modal-dialog filter-dialog-content">
          <button type="button" className="close" onClick={closePopup}>
            <span aria-hidden="true">&times;</span>
          </button>
          {/* <p className="h2 filter-header filter-header2 modal-title d-none">
            Find photos of you with a selfie
          </p> */}
          <div className="filter-content-div">
            <p className="filter-header-inner">
              {showReelPage
                ? "Please upload a selfie"
                : webcam
                ? "Take a selfie"
                : "Upload a Selfie"}
            </p>
            {showReelPage && (
              <p className="filter-selfie-p1" style={{ marginTop: "-12px" }}>
                We find photos of you using your selfie to create your unique
                showreel.
              </p>
            )}
            {webcam ? (
              <WebcamCapture
                closeWebcam={toggleWebcam}
                captured={(base64Img: string) => {
                  toggleWebcam();
                  uploadBase64Image(base64Img);
                }}
              />
            ) : (
              <UploadSelfie
                closePopup={closePopup}
                updateImageFilter={updateImageFilter}
                toggleWebcam={toggleWebcam}
                imageURL={imageURL}
                setImageURL={setImageURL}
                previewImage={previewImage}
                setPreviewImage={setPreviewImage}
                hideUploadSelfie={hideUploadSelfie}
                pageConfiguration={context.pageConfiguration}
              />
            )}
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade in" />
    </div>
  );
};

export default SelfieUploadPopup;
