import React from "react";

const SelfieIcon = ({ color }: { color: string }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9661 14.4109C11.7724 14.6039 11.5101 14.7123 11.2366 14.7123C10.9631 14.7123 10.7008 14.6039 10.507 14.4109C10.4382 14.3406 10.3561 14.2845 10.2656 14.2459C10.1751 14.2073 10.0779 14.1869 9.97944 14.1858C9.78068 14.1837 9.58924 14.2607 9.44721 14.3997C9.37689 14.4686 9.32081 14.5506 9.28219 14.6411C9.24357 14.7317 9.22316 14.8289 9.22212 14.9273C9.22002 15.1261 9.29696 15.3175 9.43602 15.4596C9.90959 15.9317 10.5511 16.1969 11.2198 16.1969C11.8885 16.1969 12.53 15.9317 13.0036 15.4596C13.1095 15.3164 13.1608 15.14 13.148 14.9623C13.1353 14.7846 13.0594 14.6174 12.9342 14.4907C12.8089 14.3641 12.6424 14.2865 12.4649 14.2718C12.2874 14.2572 12.1105 14.3065 11.9661 14.4109Z"
        fill={color}
      />
      <path
        d="M14.1443 9.95679H13.689C13.5422 9.96046 13.3998 10.0073 13.2796 10.0915C13.1593 10.1757 13.0665 10.2936 13.0129 10.4302C12.9593 10.5669 12.9471 10.7163 12.978 10.8598C13.0089 11.0034 13.0814 11.1346 13.1865 11.2371C13.2915 11.3397 13.4245 11.4089 13.5688 11.4362C13.713 11.4635 13.8621 11.4477 13.9974 11.3907C14.1327 11.3337 14.2482 11.2381 14.3294 11.1158C14.4106 10.9935 14.454 10.8499 14.454 10.7031V10.2665C14.454 10.1844 14.4214 10.1056 14.3633 10.0475C14.3052 9.98942 14.2264 9.95679 14.1443 9.95679Z"
        fill={color}
      />
      <path
        d="M8.50183 9.95679H8.04655C7.96441 9.95679 7.88562 9.98942 7.82754 10.0475C7.76945 10.1056 7.73682 10.1844 7.73682 10.2665V10.7031C7.73686 10.8499 7.7802 10.9935 7.86142 11.1158C7.94263 11.2381 8.05811 11.3337 8.19341 11.3907C8.3287 11.4477 8.4778 11.4635 8.62205 11.4362C8.76629 11.4089 8.89928 11.3397 9.00436 11.2371C9.10945 11.1346 9.18196 11.0034 9.21283 10.8598C9.2437 10.7163 9.23156 10.5669 9.17792 10.4302C9.12428 10.2936 9.03153 10.1757 8.91127 10.0915C8.79101 10.0073 8.64859 9.96046 8.50183 9.95679Z"
        fill={color}
      />
      <path
        d="M1.51729 4.73226C1.71523 4.73226 1.90507 4.65362 2.04504 4.51366C2.185 4.37369 2.26364 4.18385 2.26364 3.98591V1.84388C2.26364 1.81815 2.27386 1.79347 2.29206 1.77527C2.31025 1.75707 2.33493 1.74685 2.36066 1.74685H4.50269C4.70064 1.74685 4.89047 1.66822 5.03044 1.52825C5.17041 1.38828 5.24904 1.19845 5.24904 1.0005C5.24904 0.802557 5.17041 0.61272 5.03044 0.472752C4.89047 0.332784 4.70064 0.25415 4.50269 0.25415H2.36066C1.93934 0.255136 1.53556 0.422941 1.23764 0.72086C0.939726 1.01878 0.771921 1.42256 0.770935 1.84388V3.98591C0.770935 4.18385 0.849568 4.37369 0.989536 4.51366C1.1295 4.65362 1.31934 4.73226 1.51729 4.73226Z"
        fill={color}
      />
      <path
        d="M20.079 0.253906H17.9369C17.739 0.253906 17.5492 0.332539 17.4092 0.472507C17.2692 0.612475 17.1906 0.802313 17.1906 1.00026C17.1906 1.1982 17.2692 1.38804 17.4092 1.52801C17.5492 1.66798 17.739 1.74661 17.9369 1.74661H20.079C20.1047 1.74661 20.1294 1.75683 20.1476 1.77503C20.1658 1.79322 20.176 1.8179 20.176 1.84363V3.98566C20.176 4.18361 20.2546 4.37344 20.3946 4.51341C20.5346 4.65338 20.7244 4.73201 20.9223 4.73201C21.1203 4.73201 21.3101 4.65338 21.4501 4.51341C21.5901 4.37344 21.6687 4.18361 21.6687 3.98566V1.84363C21.6677 1.42232 21.4999 1.01853 21.202 0.720615C20.9041 0.422697 20.5003 0.254892 20.079 0.253906Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.3947 16.8925C20.5346 16.7525 20.7245 16.6739 20.9224 16.6739C21.1204 16.6739 21.3102 16.7525 21.4502 16.8925C21.5901 17.0324 21.6688 17.2223 21.6688 17.4202V19.5623C21.6678 19.9836 21.5 20.3874 21.2021 20.6853C20.9041 20.9832 20.5004 21.151 20.079 21.152H16.2353C15.6908 21.1345 15.1745 20.9059 14.7956 20.5145C14.4167 20.123 14.205 19.5995 14.2053 19.0547V19.0361L7.01416 20.9691C6.7789 21.0759 6.5258 21.1379 6.26781 21.152H2.36066C1.93934 21.151 1.53556 20.9832 1.23764 20.6853C0.939726 20.3874 0.771921 19.9836 0.770935 19.5623V17.4202C0.770935 17.2223 0.849568 17.0324 0.989536 16.8925C1.1295 16.7525 1.31934 16.6739 1.51729 16.6739C1.71523 16.6739 1.90507 16.7525 2.04504 16.8925C2.185 17.0324 2.26364 17.2223 2.26364 17.4202V19.5623C2.26364 19.588 2.27386 19.6127 2.29206 19.6309C2.31025 19.6491 2.33493 19.6593 2.36066 19.6593H3.55109C3.6853 19.3678 3.75529 19.0509 3.75634 18.7301V12.1958V9.21036V7.34449C3.75634 5.46401 4.50336 3.66056 5.83305 2.33086C7.16275 1.00117 8.9662 0.25415 10.8467 0.25415H11.593C13.4735 0.25415 15.277 1.00117 16.6067 2.33086C17.9363 3.66056 18.6834 5.46401 18.6834 7.34449V9.21036V12.1958V18.7301C18.6844 19.0509 18.7544 19.3678 18.8886 19.6593H20.079C20.1048 19.6593 20.1295 19.6491 20.1476 19.6309C20.1658 19.6127 20.1761 19.588 20.1761 19.5623V17.4202C20.1761 17.2223 20.2547 17.0324 20.3947 16.8925ZM15.4181 16.4079C16.5441 15.2945 17.1818 13.7793 17.1907 12.1958V9.87835C17.189 9.43427 17.0505 9.00149 16.7941 8.63891C16.5377 8.27633 16.1758 8.00156 15.7577 7.85201C14.7558 7.50384 13.8478 6.92917 13.1044 6.17272C12.2654 6.58959 10.1119 7.48705 8.21019 7.74192C7.41841 7.74835 6.66162 8.06905 6.1063 8.63348C5.55097 9.1979 5.24261 9.9598 5.24904 10.7516V11.0576V12.1286C5.24013 13.7122 5.86066 15.2344 6.9741 16.3604C8.08755 17.4865 9.60271 18.1241 11.1863 18.133C12.7698 18.1419 14.2921 17.5214 15.4181 16.4079Z"
        fill={color}
      />
    </svg>
  );
};
export default SelfieIcon;
