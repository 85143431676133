import ajax from "superagent";
import React from "react";
import _ from "lodash";
import Telemetry from "../../../utils/rudderstackAnalytics";

interface MultiSelectInputProps {
  appliedTags: Array<{
    name: string;
    tagId: number;
    _destroy?: boolean;
    taggableType?: string;
  }>;
  contentTagsAPI: string;
  albumId?: number;
  photoId?: Array<number>;
  handleClose: Function;
  tagType: string;
  hideInputBox?: Function;
  handlePostCall?: Function;
  reloadPage?: boolean;
  blockEdit?: string;
  parentName?: string | null;
}

interface MultiSelectInputState {
  newTag: string;
  currentTags: Array<{
    name: string;
    tagId?: number;
    _destroy?: boolean;
    taggableType?: string;
  }>;
  authenticTagsList: Array<{
    name: string;
    _destroy?: boolean;
    tagId?: number;
    taggableType?: string;
  }>;
  enableSave: boolean;
}

class MultiSelectInput extends React.Component<
  MultiSelectInputProps,
  MultiSelectInputState
> {
  constructor(props: MultiSelectInputProps) {
    super(props);
    this.state = {
      currentTags: [...this.props.appliedTags],
      authenticTagsList: [...this.props.appliedTags],
      newTag: "",
      enableSave: true
    };
  }

  removeTag = (index: number, idVar: number, idExists: boolean) => {
    let updateCurrentTags = [...this.state.currentTags];
    const idIndex = _.findIndex(this.state.authenticTagsList, {
      tagId: idVar
    });
    if (idExists) {
      const updatedTags = this.state.authenticTagsList.map((tag, i) => {
        if (i === idIndex && tag.tagId) {
          return { ...tag, _destroy: true }; // Create a new object with the additional property
        }
        return tag;
      });
      updateCurrentTags.splice(index, 1);
      this.setState({
        currentTags: updateCurrentTags,
        authenticTagsList: updatedTags,
        enableSave:
          updateCurrentTags.length == 0 && this.props.appliedTags.length === 0
            ? true
            : false
      });
    } else {
      updateCurrentTags.splice(index, 1);
      this.setState({
        currentTags: updateCurrentTags,
        authenticTagsList: updateCurrentTags,
        enableSave:
          updateCurrentTags.length == 0 && this.props.appliedTags.length === 0
            ? true
            : false
      });
    }
  };

  addTagToList = () => {
    const updatedTags = [...this.state.currentTags];
    const updateOldTags = [...this.state.authenticTagsList];
    updatedTags.push({ name: this.state.newTag });
    updateOldTags.push({ name: this.state.newTag });
    this.setState({ currentTags: updatedTags }, () =>
      this.setState({
        newTag: "",
        authenticTagsList: updateOldTags,
        enableSave: false
      })
    );
  };

  updateTagsToPhoto = () => {
    const updatedArrayOfObjects = this.state.authenticTagsList.map(obj => {
      if (obj.hasOwnProperty("tagId")) {
        const { tagId: _, ...rest } = obj; // Destructure, excluding tagId
        return {
          ...rest,
          id: obj.tagId
        };
      } else {
        return obj;
      }
    });
    const filterUpdatedArray = [...updatedArrayOfObjects].filter(
      i => i.taggableType != this.props.blockEdit
    );
    const arrayOfIds = this.props.photoId ? this.props.photoId : [];
    ajax
      .post(this.props.contentTagsAPI)
      .send({
        tags: this.props.blockEdit ? filterUpdatedArray : updatedArrayOfObjects,
        taggable_type: this.props.tagType,
        taggable_ids:
          this.props.tagType === "album" ? [this.props.albumId] : arrayOfIds
      })
      .then(res => {
        // @ts-ignore
        toastr.success("Tags Saved", null, { timeOut: 1000 });
        this.props.handlePostCall &&
          this.props.handlePostCall(this.state.currentTags);
        this.props.handleClose();
        this.props.reloadPage && window.location.reload();
        if (this.props.parentName) {
          Telemetry.track(`${this.props.parentName}:tags-saved`);
        }
      })
      .catch(err =>
        // @ts-ignore
        toastr.error("Unable to add tag. Please try again", null, 1000)
      );
  };

  handleHideTooltip = () =>
    //@ts-ignore
    $("#info-tooltip").tooltip();

  handleToggle = () =>
    $("#info-tooltip")
      .attr(
        "data-original-title",
        "Tags will help team members find and manage photos"
      )
      //@ts-ignore
      .tooltip("show");

  render() {
    return (
      <div className="multi-select-input">
        <div className="input-div">
          <span className="input-label">
            <span style={{ color: "#8b7f8f" }}>
              Type and hit enter to add a tag{" "}
            </span>
            <i
              className="fa fa-info-circle"
              id="info-tooltip"
              data-toggle="tooltip"
              onMouseEnter={() => this.handleToggle()}
              onMouseMove={() => this.handleHideTooltip()}
            ></i>
          </span>
          <input
            type="text"
            placeholder="Type and hit enter to add a tag"
            value={this.state.newTag}
            onChange={e => this.setState({ newTag: e.target.value })}
            onKeyPress={e => {
              if (e.key === "Enter") {
                this.addTagToList();
              }
            }}
          />
        </div>
        <div className="tags-list">
          {this.state.currentTags.length > 0 &&
            this.state.currentTags.map((tag, index) => (
              <div
                key={index}
                className={
                  this.props.blockEdit &&
                  this.props.blockEdit === tag.taggableType
                    ? "tag-edit-span basic-tag album-tag"
                    : "tag-edit-span basic-tag regular-tag"
                }
              >
                <span style={{ marginRight: 5 }}>{tag.name}</span>
                {this.props.blockEdit &&
                this.props.blockEdit === tag.taggableType ? null : (
                  <span
                    onClick={() =>
                      tag.tagId
                        ? this.removeTag(index, tag.tagId, true)
                        : //@ts-ignore
                          this.removeTag(index, null, false)
                    }
                  >
                    ×
                  </span>
                )}
              </div>
            ))}
        </div>
        <div className="save-div modal-footer geo-modal__actions dont-break">
          <div
            className="geo-btn geo-btn--link geo-btn--caps geo-btn--small"
            onClick={e => {
              e.preventDefault();
              this.props.hideInputBox
                ? this.props.hideInputBox()
                : this.props.handleClose();
            }}
          >
            Cancel
          </div>
          <button
            className="geo-btn geo-btn--secondary geo-btn--caps geo-btn--small"
            onClick={e => {
              e.preventDefault();
              this.updateTagsToPhoto();
            }}
            disabled={this.state.enableSave}
          >
            Save Tags
          </button>
        </div>
      </div>
    );
  }
}

export default MultiSelectInput;
