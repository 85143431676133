import React from "react";

const heartOutlineIcon = require("../../styles/icon-heart-outline.svg");
const heartFilledIcon = require("../../styles/icon-heart-filled.svg");
import Telemetry from "../../../../utils/rudderstackAnalytics";

export type OnClickFavIconProps = {
  id: number;
  isFavorite: boolean;
  source: string;
};

type FavImgProps = {
  onClickFavoriteIcon: ({
    id,
    isFavorite,
    source
  }: OnClickFavIconProps) => void;
  isFavorite: boolean;
  id: number;
  source: string;
};

const Favorite = ({
  onClickFavoriteIcon,
  isFavorite,
  id,
  source
}: FavImgProps) => {
  return (
    <>
      {isFavorite ? (
        <div
          className="geo-fav-icon-container"
          onClick={e => {
            e.stopPropagation();
            onClickFavoriteIcon({ id, isFavorite, source });
          }}
        >
          <img
            src={heartFilledIcon}
            alt="Icon to remove image from favorite list"
          />
        </div>
      ) : (
        <div
          className="geo-fav-icon-container"
          onClick={e => {
            e.stopPropagation();
            onClickFavoriteIcon({ id, isFavorite, source });
          }}
        >
          <img
            src={heartOutlineIcon}
            alt="Icon to add image to favorite list"
          />
        </div>
      )}
    </>
  );
};

export default Favorite;
