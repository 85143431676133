import React from "react";

const HideIcon = (props: { color: string | undefined }) => {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="22" cy="22" r="21.5" stroke={props.color} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3732 24.8817C10.2357 23.6385 8.46568 21.8514 7.24306 19.7021C7.08374 19.4208 7 19.1031 7 18.7799C7 18.4566 7.08374 18.1389 7.24306 17.8576C8.46568 15.7084 10.2357 13.9212 12.3732 12.6781C14.5106 11.4349 16.9392 10.78 19.4119 10.78C21.8846 10.78 24.3132 11.4349 26.4506 12.6781C28.5881 13.9212 30.3581 15.7084 31.5807 17.8576C31.7401 18.1389 31.8238 18.4566 31.8238 18.7799C31.8238 19.1031 31.7401 19.4208 31.5807 19.7021C30.3581 21.8514 28.5881 23.6385 26.4506 24.8817C24.3132 26.1249 21.8846 26.7798 19.4119 26.7798C16.9392 26.7798 14.5106 26.1249 12.3732 24.8817ZM22.795 14.6459C22.0262 14.0144 21.096 13.6105 20.1097 13.4799L20.1052 13.4888C19.2944 13.3823 18.47 13.4637 17.6957 13.7267C16.9214 13.9897 16.218 14.4273 15.6397 15.0055C15.0615 15.5837 14.624 16.2872 14.361 17.0615C14.098 17.8358 14.0166 18.6602 14.123 19.471C14.2543 20.4572 14.6589 21.3871 15.291 22.1554C15.9231 22.9238 16.7576 23.5 17.7 23.8189C18.6424 24.1379 19.6553 24.1868 20.6241 23.9603C21.5929 23.7337 22.479 23.2407 23.1823 22.5369C23.8856 21.8331 24.378 20.9466 24.6038 19.9776C24.8296 19.0087 24.7799 17.9958 24.4603 17.0536C24.1406 16.1114 23.5638 15.2774 22.795 14.6459ZM19.4444 21.8887C21.1626 21.8887 22.5555 20.4958 22.5555 18.7776C22.5555 17.0594 21.1626 15.6665 19.4444 15.6665C17.7261 15.6665 16.3333 17.0594 16.3333 18.7776C16.3333 20.4958 17.7261 21.8887 19.4444 21.8887Z"
        fill={props.color}
        style={{ transform: "translate(3px, 3.5px)" }}
      />
    </svg>
  );
};
export default HideIcon;
