import React from "react";
import "./style.css";

const ImageUploadAlert = () => (
  <p className="image-upload-terms">
    To protect your privacy, your selfie image is not saved after searching. By
    uploading your photo, you agree to biometric data from your selfie being
    used only for searching for photos in accordance with our
    <a
      href="https://geosnapshot.com/?show=allpolicies"
      rel="noopener"
      target="_blank"
    >
      {" "}
      Terms & Conditions
    </a>{" "}
  </p>
);
export default ImageUploadAlert;
